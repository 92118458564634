import React from "react"
import { theme } from "../Styles/theme"
import { Global } from "@emotion/core"
import { ThemeProvider } from "emotion-theming"
import Layout from "../Components/layout"
import { HeadingPrimary } from "../Styles/headers"
import { Section } from "../Styles/section"
import { GlobalStyles } from "../Styles/global"
export default () => (
  <ThemeProvider theme={theme}>
    <Global styles={GlobalStyles} />
    <Layout>
      <Section style={{ paddingTop: "150px" }}>
        <div wfd-id={26}>
          <HeadingPrimary>Privacy Policy</HeadingPrimary>
        </div>
        <p>Your privacy is important to us.</p>
        <p>
          We don’t ask for your personal information unless we truly need it.
        </p>
        <p>
          It is Whatsdev's policy to respect your privacy regarding any
          information we may collect from you across our website,{" "}
          <a href="http://www.Whatsdev.com">http://www.Whatsdev.com</a>.
        </p>
        <p>
          <strong>1. INTRODUCTION</strong>
        </p>
        <p>
          This privacy notice provides you with details of how we collect and
          process your personal data through your use of our site{" "}
          <strong>https://www.Whatsdev.com/</strong>
        </p>
        <p>
          &nbsp;By providing us with your data, you warrant to us that you are
          over 13 years of age.
        </p>
        <p>
          &nbsp;<strong>We are</strong> the data controller and we are
          responsible for your personal data (referred to as “we”, “us” or “our”
          in this privacy notice).
        </p>
        <p>
          &nbsp;<strong>Contact Details</strong>
        </p>
        <p>&nbsp;Our full details are:</p>
        <p>
          &nbsp;Full name of legal entity: <strong>Whatsdev</strong>
        </p>
        <p>
          &nbsp;Email address: <strong>support@Whatsdev.com</strong>
        </p>
        <p>
          &nbsp;Postal address:{" "}
          <strong>8770 West Bryn Mawr Ste #1300, Chicago IL, 60631</strong>
        </p>
        <p>
          &nbsp;It is very important that the information we hold about you is
          accurate and up to date. Please let us know if at any time your
          personal information changes by emailing us at{" "}
          <a href="mailto:support@Whatsdev.com">support@Whatsdev.com</a>
        </p>
        <p>&nbsp;</p>
        <p>
          <strong>
            2. WHAT DATA DO WE COLLECT ABOUT YOU, FOR WHAT PURPOSE AND ON WHAT
            GROUND WE PROCESS IT
          </strong>
        </p>
        <p>
          Personal data means any information capable of identifying an
          individual. It does not include anonymised data.
        </p>
        <p>
          &nbsp;We may process the following categories of personal data about
          you:
        </p>
        <li wfd-id={25}>
          <strong>Communication Data</strong> that includes any communication
          that you send to us whether that be through the contact form on our
          website, through email, text, social media messaging, social media
          posting or any other communication that you send us. We process this
          data for the purposes of communicating with you, for record keeping
          and for the establishment, pursuance or defence of legal claims. Our
          lawful ground for this processing is our legitimate interests which in
          this case are to reply to communications sent to us, to keep records
          and to establish, pursue or defend legal claims.
        </li>
        <p>&nbsp;</p>
        <li wfd-id={24}>
          j<strong>Customer Data</strong> that includes data relating to any
          purchases of goods and/or services such as your name, title, billing
          address, delivery address email address, phone number, contact
          details, purchase details and your card details. We process this data
          to supply the goods and/or services you have purchased and to keep
          records of such transactions. Our lawful ground for this processing is
          the performance of a contract between you and us and/or taking steps
          at your request to enter into such a contract
        </li>
        <p>&nbsp;</p>
        <li wfd-id={23}>
          <strong>User Data</strong> that includes data about how you use our
          website and any online services together with any data that you post
          for publication on our website or through other online services. We
          process this data to operate our website and ensure relevant content
          is provided to you, to ensure the security of our website, to maintain
          back- ups of our website and/or databases and to enable publication
          and administration of our website, other online services and business.
          Our lawful ground for this processing is our legitimate interests
          which in this case are to enable us to properly administer our website
          and our business
        </li>
        <p>&nbsp;</p>
        <li wfd-id={22}>
          <strong>Technical Data</strong> that includes data about your use of
          our website and online services such as your IP address, your login
          data, details about your browser, length of visit to pages on our
          website, page views and navigation paths, details about the number of
          times you use our website, time zone settings and other technology on
          the devices you use to access our website. The source of this data is
          from our analytics tracking system. We process this data to analyse
          your use of our website and other online services, to administer and
          protect our business and website, to deliver relevant website content
          and advertisements to you and to understand the effectiveness of our
          advertising. Our lawful ground for this processing is our legitimate
          interests which in this case are to enable us to properly administer
          our website and our business and to grow our business and to decide
          our marketing strategy
        </li>
        <p>
          <strong>&nbsp;</strong>
        </p>
        <li wfd-id={21}>
          <strong>Marketing Data</strong> that includes data about your
          preferences in receiving marketing from us and our third parties and
          your communication preferences. We process this data to enable you to
          partake in our promotions such as competitions, prize draws and free
          give-aways, to deliver relevant website content and advertisements to
          you and measure or understand the effectiveness of this advertising.
          Our lawful ground for this processing is our legitimate interests
          which in this case are to study how customers use our
          products/services, to develop them, to grow our business and to decide
          our marketing strategy.&nbsp;
        </li>
        <p>&nbsp;</p>
        <li wfd-id={20}>
          We may use Customer Data, User Data, Technical Data and Marketing Data
          to deliver relevant website content and advertisements to you
          (including Facebook adverts or other display advertisements) and to
          measure or understand the effectiveness of the advertising we serve
          you. Our lawful ground for this processing is legitimate interests
          which is to grow our business. We may also use such data to send other
          marketing communications to you. Our lawful ground for this processing
          is either consent or legitimate interests (namely to grow our
          business)
        </li>
        <p>
          <strong>Sensitive Data</strong>
        </p>
        <p>
          We do not collect any Sensitive Data about you. Sensitive data refers
          to data that includes details about your race or ethnicity, religious
          or philosophical beliefs, sex life, sexual orientation, political
          opinions, trade union membership, information about your health and
          genetic and biometric data. We do not collect any information about
          criminal convictions and offences.
        </p>
        <p>
          Where we are required to collect personal data by law, or under the
          terms of the contract between us and you do not provide us with that
          data when requested, we may not be able to perform the contract (for
          example, to deliver goods or services to you). If you don’t provide us
          with the requested data, we may have to cancel a product or service
          you have ordered but if we do, we will notify you at the time.
        </p>
        <p>
          <strong>3. HOW WE COLLECT YOUR PERSONAL DATA </strong>
        </p>
        <p>
          We may collect data about you by you providing the data directly to us
          (for example by filling in forms on our site or by sending us emails).
          We may automatically collect certain data from you as you use our
          website by using cookies and similar technologies. Please see our
          cookie policy for more details about this{" "}
          <a href="https://www.Whatsdev.com/p/cookie-policy">
            https://www.Whatsdev.com/p/cookie-policy
          </a>
        </p>
        <p>
          We may receive data from third parties such as analytics providers
          such as Google based outside the EU, advertising networks such as
          Facebook based outside the EU, such as search information providers
          such as Google based outside the EU, providers of technical, payment
          and delivery services, such as data brokers or aggregators.
        </p>
        <p>
          We may also receive data from publicly availably sources such as
          Companies House and the Electoral Register based inside the EU.
        </p>
        <p>
          <strong>4. HOW WE USE YOUR PERSONAL DATA </strong>
        </p>
        <p>
          <strong>MARKETING COMMUNICATIONS&nbsp;</strong>
          <strong>&nbsp;</strong>
        </p>
        <p>
          Our lawful ground of processing your personal data to send you
          marketing communications is either your consent or our legitimate
          interests (namely to grow our business).
        </p>
        <p>
          Before we share your personal data with any third party for their own
          marketing purposes we will get your express &nbsp;consent.
        </p>
        <p>
          You can ask us or third parties to stop sending you marketing messages
          at any time by logging into the website and checking or unchecking
          relevant boxes to adjust your marketing preferences OR by following
          the opt-out links on any marketing message sent to you or OR by
          emailing us at{" "}
          <strong>
            <a href="mailto:support@Whatsdev.com">support@Whatsdev.com</a>
          </strong>{" "}
          at any time.&nbsp;
        </p>
        <p>
          If you opt out of receiving marketing communications this opt-out does
          not apply to personal data provided as a result of other transactions,
          such as purchases, warranty registrations etc.&nbsp;
        </p>
        <p>
          <strong>5. DISCLOSURES OF YOUR PERSONAL DATA</strong>
        </p>
        <p>
          We may have to share your personal data with the parties set out
          below:
        </p>
        <li wfd-id={19}>
          Service providers who provide IT and system administration services.
        </li>
        <li wfd-id={18}>
          Professional advisers including lawyers, bankers, auditors and
          insurers
        </li>
        <li wfd-id={17}>
          Government bodies that require us to report processing activities.
        </li>
        <li wfd-id={16}>
          Third parties to whom we sell, transfer, or merge parts of our
          business or our assets.
        </li>
        <p>
          We require all third parties to whom we transfer your data to respect
          the security of your personal data and to treat it in accordance with
          the law. We only allow such third parties to process your personal
          data for specified purposes and in accordance with our instructions.
        </p>
        <p>
          <strong>6. INTERNATIONAL TRANSFERS</strong>
        </p>
        <p>
          We are subject to the provisions of the General Data Protection
          Regulations that protect your personal data. Where we transfer your
          data to third parties outside of the EEA, we will ensure that certain
          safeguards are in place to ensure a similar degree of security for
          your personal data. As such:&nbsp;
        </p>
        <li wfd-id={15}>
          We may transfer your personal data to countries that the European
          Commission have approved as providing an adequate level of protection
          for personal data by; or
        </li>
        <li wfd-id={14}>
          If we use US-based providers that are part of EU-US Privacy Shield, we
          may transfer data to them, as they have equivalent safeguards in
          place; or
        </li>
        <li wfd-id={13}>
          Where we use certain service providers who are established outside of
          the EEA, we may use specific contracts or codes of conduct or
          certification mechanisms approved by the European Commission which
          give personal data the same protection it has in Europe
        </li>
        <p>
          If none of the above safeguards is available, we may request your
          explicit consent to the specific transfer. You will have the right to
          withdraw this consent at any time.&nbsp;
        </p>
        <p>
          <strong>7. DATA SECURITY</strong>
        </p>
        <p>
          We have put in place security measures to prevent your personal data
          from being accidentally lost, used, altered, disclosed, or accessed
          without authorisation. We also allow access to your personal data only
          to those employees and partners who have a business need to know such
          data. They will only process your personal data on our instructions
          and they must keep it confidential.
        </p>
        <p>
          We have procedures in place to deal with any suspected personal data
          breach and will notify you and any applicable regulator of a breach if
          we are legally required to.
        </p>
        <p>
          <strong>8. DATA RETENTION</strong>
        </p>
        <p>
          We will only retain your personal data for as long as necessary to
          fulfil the purposes we collected it for, including for the purposes of
          satisfying any legal, accounting, or reporting requirements.
        </p>
        <p>
          When deciding what the correct time is to keep the data for we look at
          its amount, nature and sensitivity, potential risk of harm from
          unauthorised use or disclosure, the processing purposes, if these can
          be achieved by other means and legal requirements.
        </p>
        <p>
          For tax purposes the law requires us to keep basic information about
          our customers (including Contact, Identity, Financial and Transaction
          Data) for six years after they stop being customers.
        </p>
        <p>
          In some circumstances we may anonymise your personal data for research
          or statistical purposes in which case we may use this information
          indefinitely without further notice to you.
        </p>
        <p>
          <strong>9. YOUR LEGAL RIGHTS</strong>
        </p>
        <p>
          Under data protection laws you have rights in relation to your
          personal data that include the right to request access, correction,
          erasure, restriction, transfer, to object to processing, to
          portability of data and (where the lawful ground of processing is
          consent) to withdraw consent.
        </p>
        <p>You can see more about these rights at:</p>
        <p>
          https://ico.org.uk/for-organisations/guide-to-the-general-data-protection-regulation-gdpr/individual-rights/
        </p>
        <p>
          If you wish to exercise any of the rights set out above, please email
          us at <strong>support@Whatsdev.com</strong>
        </p>
        <p>
          You will not have to pay a fee to access your personal data (or to
          exercise any of the other rights). However, we may charge a reasonable
          fee if your request is clearly unfounded, repetitive or excessive or
          refuse to comply with your request in these circumstances.
        </p>
        <p>
          We may need to request specific information from you to help us
          confirm your identity and ensure your right to access your personal
          data (or to exercise any of your other rights). This is a security
          measure to ensure that personal data is not disclosed to any person
          who has no right to receive it. We may also contact you to ask you for
          further information in relation to your request to speed up our
          response.
        </p>
        <p>
          We try to respond to all legitimate requests within one month.
          Occasionally it may take us longer than a month if your request is
          particularly complex or you have made a number of requests. In this
          case, we will notify you.
        </p>
        <p>
          If you are not happy with any aspect of how we collect and use your
          data, you have the right to complain to the Information Commissioner’s
          Office (ICO), the UK supervisory authority for data protection issues
          (<a href="http://www.ico.org.uk/">www.ico.org.uk</a>). We should be
          grateful if you would contact us first if you do have a complaint so
          that we can try to resolve it for you.
        </p>
        <p>
          <strong>10. THIRD-PARTY LINKS</strong>
        </p>
        <p>
          This website may include links to third-party websites, plug-ins and
          applications. Clicking on those links or enabling those connections
          may allow third parties to collect or share data about you. We do not
          control these third-party websites and are not responsible for their
          privacy statements. When you leave our website, we encourage you to
          read the privacy notice of every website you visit.
        </p>
        <p>
          <strong>11. COOKIES</strong>
        </p>
        <p>
          You can set your browser to refuse all or some browser cookies, or to
          alert you when websites set or access cookies. If you disable or
          refuse cookies, please note that some parts of this website may become
          inaccessible or not function properly. For more information about the
          cookies we use, please see{" "}
          <a href="https://www.Whatsdev.com/p/cookie-policy">
            https://www.Whatsdev.com/p/cookie-policy
          </a>
        </p>
        <div wfd-id={12}>
          <strong>CALIFORNIA PRIVACY RIGHTS</strong>
          <br />
          <br />
        </div>
        <div wfd-id={11}>
          The State of California has established its own unique regulations
          that apply to California residents. If you reside in California, you
          have the right to obtain from us, once per year and free of charge,
          information regarding what information we disclose to third party
          marketers, and the names and addresses of each third party to whom we
          disclose your data. If you are a California resident and would like to
          make such a request, please use the contact information listed below.
          <br />
          <br />
        </div>
        <div wfd-id={10}>
          If you are a California resident and under the age of 18, you have the
          right to request that we remove any data that you publicly post on our
          website. To request removal of your data, please publicly posted on
          our website, we may not be able to completely remove that data from
          our systems.
          <br />
          <br />
        </div>
        <div wfd-id={9}>
          <strong>CALIFORNIA ONLINE PRIVACY PROTECTION ACT</strong>
          <br />
          <br />
        </div>
        <div wfd-id={8}>
          CalOPPA is the first state law in the nation to require commercial
          websites and online services to post a privacy policy. The law's reach
          stretches well beyond California to require any person or company in
          the United States (and conceivably the world) that operates websites
          collecting Personally Identifiable Information from California
          consumers to post a conspicuous privacy policy on its website stating
          exactly the information being collected and those individuals or
          companies with whom it is being shared. See more&nbsp;
          <a
            href="http://consumercal.org/california-online-privacy-protection-act-caloppa/#sthash.0FdRbT51.dpuf"
            target="_blank"
            rel="noopener noreferrer"
          >
            here
          </a>
          .<br />
          <br />
        </div>
        <div wfd-id={7}>According to CalOPPA, we agree to the following:</div>
        <li wfd-id={6}>
          Users can visit our site anonymously.
          <br />
          <br />
        </li>
        <li wfd-id={5}>
          Once this privacy policy is created, we will add a link to it on our
          home page or as a minimum, on the first significant page after
          entering our website.
          <br />
          <br />
        </li>
        <li wfd-id={4}>
          Our Privacy Policy link includes the word 'Privacy' and can easily be
          found on the page specified above.
        </li>
        <div wfd-id={3}>
          You will be notified on this page of any Privacy Policy changes
          <br />
          <br />
        </div>
        <div wfd-id={2}>
          You can change your personal information by emailing us at{" "}
          <a href="mailto:support@Whatsdev.com">support@Whatsdev.com</a>
        </div>
      </Section>
    </Layout>
  </ThemeProvider>
)
